import React, {useEffect, useState} from 'react'
import {toast, ToastContainer} from 'react-toastify'

import {NoResumeIcon, GroupIcon, DownloadIcon} from 'src/components/icons'
import {downloadResume, fetchResumeReportCount} from 'src/lib/util'

import {Box, Flex, Grid} from './Box'
import Loading from './Loading'
import 'react-toastify/dist/ReactToastify.css'

const ResumeBuilder = () => {
  const [resumesCompleted, setResumesCompleted] = useState<number | null>(null)

  const handleDownload = async () => {
    const result = await downloadResume()

    if (result.success) {
      toast.success('Report downloaded successfully!', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      })
    } else {
      toast.error(`Error: ${result.message}`, {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      })
    }
  }

  useEffect(() => {
    const fetchCount = async () => {
      const result = await fetchResumeReportCount()
      if (result.success) {
        setResumesCompleted(result.count)
      } else {
        setResumesCompleted(0)
      }
    }

    fetchCount()
  }, [])

  return resumesCompleted !== null ? (
    <>
      <ToastContainer position="top-center" autoClose={3000} theme="light" />
      <Flex color="grey5">
        <Box mt={3} color="#021546">
          <NoResumeIcon mr={3} /># of Resumes Completed: {resumesCompleted}
        </Box>
      </Flex>
      {resumesCompleted > 0 && (
        <Flex mt={3} color="#021546" alignItems="center">
          <GroupIcon mt={-4} mr={3} />
          <Grid>
            <span>Latest Resume Builder Report</span>
            <span>
              <DownloadIcon onClick={handleDownload} cursor="pointer" ml={3} />
            </span>
          </Grid>
        </Flex>
      )}
    </>
  ) : (
    <Loading size="medium" />
  )
}

export default ResumeBuilder
